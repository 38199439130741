import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';

import { useMatomo } from '@datapunt/matomo-tracker-react';

import getViewData from './data/ViewReader';
import EntryPageMessage from './entry/EntryPageMessage';
import ImageCard from './cards/ImageCard';
import CarouselImageItem from './carousel/CarouselImageItem';
import ImageCardContent from './cards/ImageCardContent';
import VideoCard from './cards/VideoCard';
import VideoCardContent from './cards/VideoCardContent';
import AudioCard from './cards/AudioCard';
import YoutubeCard from './cards/YoutubeCard';
import CarouselVideoItem from './carousel/CarouselVideoItem';
import TextCard from './cards/TextCard';
import QuoteCard from './cards/QuoteCard';
import ScrambledWordCard from './cards/ScrambledWordCard';
import SurveyCard from './cards/SurveyCard';
import InfosCard from './cards/InfosCard';
import CompetitionCard from './cards/CompetitionCard';
import ActionCard from './cards/ActionCard';
import DeeplinkButton from './cards/DeeplinkButton';
import DealButton from './cards/DealButton';
import ShareCard from './share/ShareCard';
import CarouselManager from './carousel/CarouselManager';
import Config from './config/ui_settings.json';
import L from './util/L10n';
import Columns from './entrylayout/Columns';
import TopSliderBuilder from './entrylayout/SliderBuilder';
import MediaSliderBuilder from './entrylayout/MediaSliderBuilder';
import FullBuilder from './entrylayout/FullBuilder';
import SectionTitle from './entrylayout/SectionTitle';
import TextUtil from './util/TextUtil';
import PageHelmet from './util/PageHelmet';
import EntryUrl from './util/EntryUrl';
import AltText from './util/AltText';
import headViewData from "./data/ViewReaderWithHeadRequest";
import ExternalContentCard from "./cards/ExternalContentCard";

const useStyles = makeStyles({
});

class EntryPage extends PureComponent {
    constructor(props) {
        super(props);
        const historyState = props.location.state || {};
        let data = null;
        let lastModified = null;
        if (historyState.data) {
            data = historyState.data;
            lastModified = historyState.lastModified;
        }
        const extendedId = props.routing.match.params.id;
        const id = extendedId ? extendedId.split('-')[0] : '';
        this.state = {
            id: id,
            data: data,
            lastModified: lastModified || null,
            error: null,
            twoColumnLayout: props.twoColumnLayout,
            layoutLocked: false,
        }
        this.onDataReceived = this._onDataReceived.bind(this);
        this.lockLayout = this._lockLayout.bind(this);
    }

    componentDidMount() {
        if (window.FgfChannel && this.props.routing) {
            window.FgfChannel.postMessage("onPathChanged:" + this.props.routing.location.pathname);
        }
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            // Ignore.
        }
        this.load();
    }

    componentDidUpdate() {
        if (!this.state.layoutLocked) {
            const { twoColumnLayout, } = this.props;
            if (twoColumnLayout !== this.state.twoColumnLayout) {
                this.setState({ twoColumnLayout, });
            }
        }
    }

    _lockLayout(locked) {
        if (!!locked !== this.state.layoutLocked) {
            this.setState({ layoutLocked: locked, });
        }
    }

    load() {
        const { appServerPort, previewToken, previewDate } = this.props;
        const { data, lastModified } = this.state;
        if (!data) {
            getViewData(this.state.id, null, this.onDataReceived,
                appServerPort, previewToken).catch((error) => {
                    // console.warn('EntryPage.load', error);
                    this.setState({ error: error, });
                })
        } else {
            headViewData(this.state.id, null, this.onDataReceived,
                appServerPort, previewToken,
                previewDate, lastModified).catch((error) => {
                    //console.log(error)
                    this.setState({ error: error });
                });
        }
    }

    _onDataReceived(data, lastModified) {
        const { history, location } = this.props;
        const state = location.state || {};
        state.data = data;
        state.lastModified = lastModified;
        history.replace({ state });
        this.setState({ data: data, lastModified: lastModified, error: null })
    }


    render() {
        const { userLang } = this.props;
        if (this.state.error) {
            return (
                <EntryPageMessage
                    message={L.t('msgEntryPageFailed', userLang)}
                />
            );
        } else if (this.state.data) {
            const {
                documentTitle, description, canonicalUrl, ogImage, ogLocale,
                ogType, ogArticlePublishedTime, ogArticleExpirationTime,
            } = this.buildMetaData();
            return (
                <Grid container direction='row' justify='center' alignItems='center' spacing={Config.gridSpacing}>
                    <PageHelmet
                        documentTitle={documentTitle}
                        metaDescription={description}
                        canonicalUrl={canonicalUrl}
                        ogTitle={documentTitle}
                        ogDescription={description}
                        ogUrl={canonicalUrl}
                        ogImage={ogImage}
                        ogLocale={ogLocale}
                        ogType={ogType}
                        ogArticlePublishedTime={ogArticlePublishedTime}
                        ogArticleExpirationTime={ogArticleExpirationTime}
                    />
                    {this.renderContent()}
                </Grid>
            );
        } else {
            return (
                <EntryPageMessage
                    message={L.t('msgEntryPageLoading', userLang)}
                />
            );
        }
    }

    renderContent() {
        const { textOnly } = this.props;
        const { twoColumnLayout, data } = this.state;
        const columns = this.buildPositions(data);
        if (twoColumnLayout && !textOnly) {
            return columns.getDouble();
        } else {
            return columns.getSingle();
        }
    }

    buildMetaData() {
        const { userLang } = this.props;
        let documentTitle = L.t('appTitle', userLang);
        const {
            title, kacheln, publish_from, publish_till,
        } = this.state.data;
        if (title) {
            documentTitle = title + ' - ' + documentTitle;
        }
        const descriptionText = new AltText();
        const canonicalUrl = EntryUrl.getUrl(this.state.data);
        let ogImage = undefined;
        for (let kachel of kacheln) {
            if (!ogImage && "uestra/altkachel" === kachel.type &&
                'big' === kachel.teaser && kachel.mediaURL) {
                ogImage = kachel.mediaURL;
                descriptionText.append(kachel.subheader);
                descriptionText.append(kachel.header);
                descriptionText.append(kachel.text);
            }
        }
        const description = descriptionText.toString();
        // TODO The content locale is fixed to German, therefore, the locale
        // if fixed here, too.
        const ogLocale = 'de_DE';
        const ogType = 'article';
        const ogArticlePublishedTime = publish_from;
        const ogArticleExpirationTime = publish_till;
        return ({
            documentTitle, description, canonicalUrl, ogImage, ogLocale,
            ogType, ogArticlePublishedTime, ogArticleExpirationTime,
        });
    }

    buildPositions(entry) {
        const {
            textOnly, topSliderMaxElements, mediaSliderMaxElements,
        } = this.props;
        const columns = new Columns();
        let pos = 0;
        let positions = [];
        let topSlider = [];
        let mediaSlider = [];
        let pressReleases = [];
        for (let kachel of entry.kacheln) {
            if (kachel.teaser) {
                continue;
            }
            if (('uestra/altkachel' === kachel.type ||
                'uestra/videokachel' === kachel.type ||
                'uestra/audiokachel' === kachel.type) &&
                kachel.mediaURL) {
                if (kachel.slider) {
                    topSlider.push(kachel);
                } else if (kachel.mediaSlider) {
                    mediaSlider.push(kachel);
                } else {
                    positions.push(kachel);
                }
            } else if ('uestra/text' === kachel.type &&
                !TextUtil.isEmptyText(kachel.content)) {
                positions.push(kachel);
            } else if ('uestra/quote' === kachel.type &&
                !TextUtil.isEmptyText(kachel.quote)) {
                positions.push(kachel);
            } else if ('uestra/external' === kachel.type &&
                !TextUtil.isEmptyText(kachel.extType)) {
                positions.push(kachel);
            } else if ('uestra/shakeword' === kachel.type &&
                !TextUtil.isEmptyText(kachel.shaked) &&
                !TextUtil.isEmptyText(kachel.solved)) {
                positions.push(kachel);
            } else if ('uestra/survey' === kachel.type &&
                !TextUtil.isEmptyText(kachel.question) &&
                !TextUtil.isEmptyText(kachel.answer1) &&
                !TextUtil.isEmptyText(kachel.answer2)) {
                positions.push(kachel);
            } else if ('uestra/deeplinkbutton' === kachel.type &&
                !TextUtil.isEmptyText(kachel.url) &&
                !TextUtil.isEmptyText(kachel.title)) {
                positions.push(kachel);
            } else if ('uestra/infos' === kachel.type &&
                !TextUtil.isEmptyText(kachel.label1) &&
                !TextUtil.isEmptyText(kachel.url1)) {
                positions.push(kachel);
            } else if ('uestra/competition' === kachel.type &&
                !TextUtil.isEmptyText(kachel.id)) {
                positions.push(kachel);
            } else if ('uestra/action' === kachel.type &&
                !TextUtil.isEmptyText(kachel.id)) {
                positions.push(kachel);
            } else if ('uestra/deal' === kachel.type &&
                !TextUtil.isEmptyText(kachel.id) &&
                !TextUtil.isEmptyText(kachel.buttonText) &&
                !TextUtil.isEmptyText(kachel.email)) {
                positions.push(kachel);
            } else if ('uestra/mediaslider' === kachel.type) {
                positions.push(kachel);
            } else if ('uestra/pressrelease' === kachel.type &&
                !TextUtil.isEmptyText(kachel.title) &&
                !TextUtil.isEmptyText(kachel.text)) {
                pressReleases.push(kachel);
            } else if ('uestra/youtube' === kachel.type &&
                !TextUtil.isEmptyText(kachel.youtubeId)) {
                positions.push(kachel);
            } else {
                // console.warn('Unhandled tile type', kachel.type);
            }
        }
        if (topSlider.length > 0) {
            if (textOnly) {
                let cardno = 0;
                for (let kachel of topSlider) {
                    if (topSliderMaxElements === 0 ||
                        cardno < topSliderMaxElements) {
                        columns.addFull(this.buildFull(kachel, pos));
                        cardno += 1;
                        pos += 1;
                    }
                }
            } else {
                columns.addSlider(this.buildTopSlider(topSlider, pos,
                    topSliderMaxElements));
                pos += 1;
            }
        }
        let mediaSliderAdded = false;
        let pressReleasesAdded = false;
        for (let position of positions) {
            if ('uestra/altkachel' === position.type) {
                columns.addFull(this.buildFull(position, pos));
            } else if ('uestra/videokachel' === position.type) {
                columns.addFull(this.buildVideo(position, pos));
            } else if ('uestra/audiokachel' === position.type) {
                columns.addFull(this.buildAudio(position, pos));
            } else if ('uestra/youtube' === position.type) {
                columns.addFull(this.buildYoutube(position, pos));
            } else if ('uestra/text' === position.type) {
                columns.addFull(this.buildText(position, pos));
            } else if ('uestra/quote' === position.type) {
                columns.addFull(this.buildQuote(position, pos));
            } else if ('uestra/external' === position.type) {
                columns.addFull(this.buildExternalContent(position, pos));
            } else if ('uestra/shakeword' === position.type) {
                columns.addFull(this.buildScrambledWord(position, pos));
            } else if ('uestra/survey' === position.type) {
                columns.addFull(this.buildSurvey(position, pos));
            } else if ('uestra/deeplinkbutton' === position.type) {
                columns.addFull(this.buildDeeplinkButton(position, pos));
            } else if ('uestra/infos' === position.type) {
                if (pressReleasesAdded || pressReleases.length === 0) {
                    columns.addFull(this.buildInfos(position, pos, null));
                } else {
                    columns.addFull(this.buildInfos(position, pos,
                        pressReleases));
                    pressReleasesAdded = true;
                }
            } else if ('uestra/competition' === position.type) {
                columns.addFull(this.buildCompetition(position, pos, entry.id));
            } else if ('uestra/action' === position.type) {
                columns.addFull(this.buildAction(position, pos, entry));
            } else if ('uestra/deal' === position.type) {
                columns.addFull(this.buildDeal(position, pos, entry));
            } else if ('uestra/mediaslider' === position.type
                && !mediaSliderAdded && mediaSlider.length > 0) {
                if (textOnly) {
                    columns.addSlider(this.buildMediaSliderTitle(pos,
                        position.title));
                    let cardno = 0;
                    for (let kachel of mediaSlider) {
                        if (mediaSliderMaxElements === 0 ||
                            cardno < mediaSliderMaxElements) {
                            columns.addFull(this.buildFull(kachel, pos));
                            cardno += 1;
                            pos += 1;
                        }
                    }
                } else {
                    columns.addSlider(this.buildMediaSlider(mediaSlider, pos,
                        position.title, mediaSliderMaxElements));
                }
                mediaSliderAdded = true;
            }
            pos += 1;
        }
        if (!mediaSliderAdded && mediaSlider.length > 0) {
            if (textOnly) {
                columns.addSlider(this.buildMediaSliderTitle(pos, ''));
                let cardno = 0;
                for (let kachel of mediaSlider) {
                    if (mediaSliderMaxElements === 0 ||
                        cardno < mediaSliderMaxElements) {
                        columns.addFull(this.buildFull(kachel, pos));
                        cardno += 1;
                        pos += 1;
                    }
                }
            } else {
                columns.addSlider(this.buildMediaSlider(mediaSlider, pos, '',
                    mediaSliderMaxElements));
                pos += 1;
            }
        }
        if (!pressReleasesAdded && pressReleases.length > 0) {
            columns.addFull(this.buildInfos({}, pos, pressReleases));
            pos += 1;
        }
        if (entry.share) {
            columns.addFull(this.buildShare(entry, pos));
            pos += 1;
        }
        columns.finish();
        return columns;
    }

    buildTopSlider(entries, pos, maxElements) {
        const { userLang, fullscreenVideo, } = this.props;
        const cardsMgr = this.buildSliderCards(entries, pos);
        return TopSliderBuilder.build(cardsMgr[0], pos, userLang,
            cardsMgr[1], fullscreenVideo, cardsMgr[2], maxElements);
    }

    buildMediaSlider(entries, pos, title, maxElements) {
        const { userLang, fullscreenVideo, } = this.props;
        const titleText = TextUtil.isEmptyText(title) ?
            L.t('mediaSliderTitle', userLang) : title;
        const sliderTitle =
            <SectionTitle
                title={titleText}
                showMediaIcon={TextUtil.isEmptyText(title)}
            />;
        const cardsMgr = this.buildSliderCards(entries, pos);
        return MediaSliderBuilder.build(cardsMgr[0], pos, this.props.userLang,
            cardsMgr[1], sliderTitle, fullscreenVideo, cardsMgr[2],
            maxElements);
    }

    buildMediaSliderTitle(pos, title) {
        const { userLang } = this.props;
        const titleText = TextUtil.isEmptyText(title) ?
            L.t('mediaSliderTitle', userLang) : title;
        const sliderTitle =
            <SectionTitle
                title={titleText}
                showMediaIcon={TextUtil.isEmptyText(title)}
            />;
        return MediaSliderBuilder.buildTitle(pos, sliderTitle);
    }

    buildSliderCards(kacheln, pos) {
        const {
            videoQuality, videoQualityCallback, deviceSize,
            fullscreenVideo, fullscreenVideoCallback, userLang,
        } = this.props;
        let cards = [];
        const mgr = new CarouselManager();
        const duplicates = [];
        let cardno = 0;
        if (kacheln.length === 1) {
            const kachel = kacheln[0];
            if ('uestra/altkachel' === kachel.type) {
                cards.push(
                    <ImageCardContent
                        key={'gridpos' + pos + 'card' + cardno}
                        data={kachel}
                        externalLink={kachel.clickURL}
                        deviceSize={deviceSize}
                    />
                );
            } else if ('uestra/videokachel' === kachel.type) {
                cards.push(
                    <VideoCardContent
                        key={'gridpos' + pos + 'card' + cardno}
                        data={kachel}
                        videoQuality={videoQuality}
                        videoQualityCallback={videoQualityCallback}
                        fullscreenVideo={fullscreenVideo}
                        fullscreenVideoCallback={fullscreenVideoCallback}
                        playsInline={true}
                        userLang={userLang}
                    />
                );
            } else {
                // console.warn('Unhandled tile type in slider', kachel.type);
            }
        } else {
            for (let kachel of kacheln) {
                if ('uestra/altkachel' === kachel.type) {
                    cards.push(
                        <CarouselImageItem
                            key={'gridpos' + pos + 'card' + cardno}
                            data={kachel}
                            manager={mgr}
                            sliderItem={cardno}
                            externalLink={kachel.clickURL}
                            deviceSize={deviceSize}
                        />
                    );
                    duplicates.push(
                        <CarouselImageItem
                            key={'gridpos' + pos + 'card' + cardno}
                            data={kachel}
                            manager={mgr}
                            sliderItem={cardno}
                            externalLink={kachel.clickURL}
                            deviceSize={deviceSize}
                            duplicate={true}
                        />
                    );
                } else if ('uestra/videokachel' === kachel.type) {
                    cards.push(
                        <CarouselVideoItem
                            key={'gridpos' + pos + 'card' + cardno}
                            data={kachel}
                            manager={mgr}
                            sliderItem={cardno}
                            videoQuality={videoQuality}
                            videoQualityCallback={videoQualityCallback}
                            fullscreenVideo={fullscreenVideo}
                            fullscreenVideoCallback={fullscreenVideoCallback}
                            userLang={userLang}
                        />
                    );
                    duplicates.push(
                        <CarouselVideoItem
                            key={'gridpos' + pos + 'card' + cardno}
                            data={kachel}
                            manager={mgr}
                            sliderItem={cardno}
                            videoQuality={videoQuality}
                            videoQualityCallback={videoQualityCallback}
                            fullscreenVideo={fullscreenVideo}
                            fullscreenVideoCallback={fullscreenVideoCallback}
                            duplicate={true}
                            userLang={userLang}
                        />
                    );
                } else {
                    // console.warn('Unhandled tile type in slider', kachel.type);
                }
                cardno++;
            }
        }
        return [cards, mgr, duplicates.slice(0, 2)];
    }

    buildFull(kachel, pos) {
        const { deviceSize, textOnly } = this.props;
        const card =
            <ImageCard
                key={'gridposcard' + pos}
                data={kachel}
                externalLink={kachel.clickURL}
                deviceSize={deviceSize}
                textOnly={textOnly}
            />;
        return FullBuilder.build(card, pos);
    }

    buildText(kachel, pos) {
        const card =
            <TextCard
                key={'gridposcard' + pos}
                data={kachel}
            />;
        return FullBuilder.build(card, pos);
    }

    buildScrambledWord(kachel, pos) {
        const { userLang,showAnimation, stopAnimation } = this.props;
        const card =
            <ScrambledWordCard
                key={'gridposcard' + pos}
                data={kachel}
                userLang={userLang}
                showAnimation={showAnimation}
                stopAnimation={stopAnimation}
            />;
        return FullBuilder.build(card, pos);
    }

    buildExternalContent(kachel, pos) {
        const { userLang } = this.props;
        const card =
            <ExternalContentCard
            key={'gridposcard' + pos}
            userLang={userLang}
            data={kachel}
        />;
        return FullBuilder.build(card, pos);
    }

    buildSurvey(kachel, pos) {
        const { userLang } = this.props;
        const {data} = this.state;
        const card =
            <SurveyCard
                key={'gridposcard' + pos}
                data={kachel}
                userLang={userLang}
                parentEntry={data}
            />;
        return FullBuilder.build(card, pos);
    }

    buildQuote(kachel, pos) {
        const { userLang } = this.props;
        const card =
            <QuoteCard
                key={'gridposcard' + pos}
                data={kachel}
                userLang={userLang}
            />;
        return FullBuilder.build(card, pos);
    }

    buildDeeplinkButton(kachel, pos) {
        const card =
            <DeeplinkButton
                key={'gridposcard' + pos}
                data={kachel}
            />;
        return FullBuilder.build(card, pos);
    }

    buildInfos(kachel, pos, pressReleases) {
        const { userLang } = this.props;
        const card =
            <InfosCard
                key={'gridposcard' + pos}
                data={kachel}
                userLang={userLang}
                pressReleases={pressReleases}
            />;
        return FullBuilder.build(card, pos);
    }

    buildShare(entry, pos) {
        const { userLang } = this.props;
        const card =
            <ShareCard
                key={'gridposcard' + pos}
                userLang={userLang}
                entry={entry}
            />;
        return FullBuilder.build(card, pos);
    }

    buildCompetition(kachel, pos, entryId) {
        const { userLang } = this.props;
        const card =
            <CompetitionCard
                key={'gridposcard' + pos}
                data={kachel}
                entryId={entryId}
                userLang={userLang}
                lockLayout={this.lockLayout}
            />;
        return FullBuilder.build(card, pos);
    }

    buildAction(kachel, pos, entry) {
        const { userLang, showAnimation, stopAnimation } = this.props;
        const card =
            <ActionCard
                key={'gridposcard' + pos}
                data={kachel}
                entry={entry}
                userLang={userLang}
                lockLayout={this.lockLayout}
                showAnimation={showAnimation}
                stopAnimation={stopAnimation}
            />;
        return FullBuilder.build(card, pos);
    }

    buildDeal(kachel, pos, entry) {
        const { userLang } = this.props;
        const card =
            <DealButton
                key={'gridposcard' + pos}
                data={kachel}
                entry={entry}
                userLang={userLang}
            />;
        return FullBuilder.build(card, pos);
    }

    buildVideo(kachel, pos) {
        const {
            videoQuality, videoQualityCallback,
            fullscreenVideo, fullscreenVideoCallback, userLang,
        } = this.props;
        const card =
            <VideoCard
                key={'gridposcard' + pos}
                data={kachel}
                videoQuality={videoQuality}
                videoQualityCallback={videoQualityCallback}
                fullscreenVideo={fullscreenVideo}
                fullscreenVideoCallback={fullscreenVideoCallback}
                userLang={userLang}
            />;
        return FullBuilder.build(card, pos);
    }

    buildAudio(kachel, pos) {
        const { userLang } = this.props;
        const card =
            <AudioCard
                key={'gridposcard' + pos}
                data={kachel}
                userLang={userLang}
            />;
        return FullBuilder.build(card, pos);
    }

    buildYoutube(kachel, pos) {
        const card =
            <YoutubeCard
                key={'gridposcard' + pos}
                data={kachel}
            />;
        return FullBuilder.build(card, pos);
    }
}

EntryPage.propTypes = {
    routing: PropTypes.object.isRequired,
    appServerPort: PropTypes.number,
    previewToken: PropTypes.string,
    previewDate: PropTypes.string,
    userLang: PropTypes.string,
    videoQuality: PropTypes.number,
    videoQualityCallback: PropTypes.func,
    deviceSize: PropTypes.string,
    fullscreenVideo: PropTypes.bool,
    fullscreenVideoCallback: PropTypes.func,
    textOnly: PropTypes.bool,
    topSliderMaxElements: PropTypes.number,
    mediaSliderMaxElements: PropTypes.number,
    twoColumnLayout: PropTypes.bool,
    showAnimation: PropTypes.func.isRequired,
    stopAnimation: PropTypes.func.isRequired,
};

EntryPage.defaultProps = {
    topSliderMaxElements: 10,
    mediaSliderMaxElements: 20,
}

export default withRouter((props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isLandscape = useMediaQuery('@media (orientation: landscape)');
    const isWideScreen =
        useMediaQuery(theme.breakpoints.up(Config.entryWidescreenMinWidth));
    const twoColumnLayout = isLandscape || isWideScreen;
    let documentTitle = L.t('appTitle', props.userLang);
    if (props.routing.location.state) {
        const data = props.routing.location.state.data;
        if (data && data.title) {
            documentTitle = data.title + ' - ' + documentTitle;
        }
    }
    const { trackPageView, } = useMatomo();
    React.useEffect(() => {
        trackPageView({ documentTitle, });
    }, [trackPageView, documentTitle]);
    return (
        <EntryPage
            {...props}
            classes={classes}
            twoColumnLayout={twoColumnLayout}
            documentTitle={documentTitle}
        />
    );
});
